export const GAE_APP = "https://new-lantern.uc.r.appspot.com/";

export const NL_EXTENSION_EVENTS = {
  NEW_LANTERN_WORKLIST_OPENED: "NEW_LANTERN_WORKLIST_OPENED",
  NEW_LANTERN_VIEWER_OPENED: "NEW_LANTERN_VIEWER_OPENED",
  NEW_LANTERN_SET_USER: "NEW_LANTERN_SET_USER",
  NEW_LANTERN_SET_STUDY: "NEW_LANTERN_SET_STUDY",
};

export const DEFAULT_HANGING_PROTOCOL = {
  name: "",
  description: "",
  study_description: [],
  body_part_examined: [],
  modality: [],
  grid_matrix: [],
  hanging_protocol_criterias: [],
};

export const ROTATION_ANGLE_LIST = [-90, 0, 90, 180];

export const PRIOR_STUDY_LIST = [
  {
    value: 0,
    label: "Current",
  },
  {
    value: 1,
    label: "First",
  },
  {
    value: 2,
    label: "Second",
  },
  {
    value: 3,
    label: "Third",
  },
];

export const DEFAULT_HANGING_PROTOCOL_CRITERIA = {
  rotation_angle: ROTATION_ANGLE_LIST[1],
  viewport_scaling: 100,
  image_orientation_plane: null,
  viewport_flip: null,
  prior_study: PRIOR_STUDY_LIST[0].value,
  matrix_position: [],
  series_description: [],
};

export const ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

export const DEFAULT_ROWS_PER_PAGE = ROWS_PER_PAGE_OPTIONS[0];

export const TABLE_HEIGHT_OFFSET = 240;
