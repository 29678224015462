import React, { useState, type ChangeEvent } from "react";
import { Box, Button, Dialog, DialogContent, Typography, Grid } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import useStyles from "styles/studyListStyles";
import toast from "react-hot-toast";
import { uploadZip } from "api/upload";

const fileUpload = (file: File) => {
  toast.promise(
    (async () => {
      if (!file.name.endsWith(".zip")) throw new Error("Not A Zip File");

      const form = new FormData();
      form.append("file", file);
      const req = await uploadZip(file.name, form);

      if (req.status !== 200) {
        throw new Error("File Failed To Upload");
      }
    })(),
    {
      loading: `Uploading ${file.name}`,
      success: `Uploaded ${file.name}!`,
      error: `Failed to upload ${file.name}`,
    }
  );
};

export default function FileUpload() {
  const classes = useStyles();
  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [drag, setDrag] = useState(false);

  return (
    <Box>
      <Button
        variant="outlined"
        startIcon={<CloudUpload />}
        className={classes.uploadDicomButton}
        onClick={() => setFileUploadModal(true)}
        data-testid="study-list-upload-dicom"
      >
        Upload
      </Button>
      <Dialog open={fileUploadModal} onClose={() => setFileUploadModal(false)}>
        <DialogContent style={{ background: "#0F172A", padding: "12px", borderRadius: "8px" }}>
          <Box
            sx={{ bgcolor: "#020617", borderRadius: "8px" }}
            style={{ filter: `brightness(${drag ? 1.5 : 1})` }}
            onDragOver={e => {
              e.preventDefault();
              setDrag(true);
            }}
            onDragLeave={e => {
              e.preventDefault();
              setDrag(false);
            }}
            onDrop={async e => {
              e.preventDefault();
              setDrag(false);
              fileUpload(e.dataTransfer.files[0]);
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignContent="center"
              style={{ height: "250px", width: "400px", position: "relative" }}
            >
              <Button
                style={{
                  textTransform: "unset",
                  border: "3px dashed",
                  minWidth: "250px",
                  padding: "1rem",
                  borderRadius: "8px",
                  borderColor: "#F1F5F9",
                }}
                component="label"
              >
                <Typography variant="h5" style={{ color: "white" }}>
                  Drop Zip Here
                </Typography>
                <input
                  type="file"
                  name="file"
                  hidden
                  onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files !== null) fileUpload(e.target.files[0]);
                  }}
                />
              </Button>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
