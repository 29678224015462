import React, { createContext, useContext, useState } from "react";
import { omit } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import qs, { ParsedQuery } from "query-string";
import { Patient } from "types/models/Patient";
import { Study } from "types/models/Study";
import { ProviderProps } from "types/ProviderProps";
import { test } from "@jest/globals";
import { Report } from "types/models/Report";

type AppContextType = {
  navigation: {
    query: ParsedQuery;
    processChange: (field: string, newValue: number | string | string[], path?: string) => void;
    processPageSizeChange: (newValue: string | number) => void;
  };
  patient?: Patient;
  setPatient: (patient: Patient | undefined) => void;
  study?: Study;
  setStudy: (study: Study | undefined) => void;
  report?: Report;
  setReport: (report: Report | undefined) => void;
};

const AppContext = createContext<AppContextType>({} as any);

export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }: ProviderProps) => {
  const app = useAppHook();

  return <AppContext.Provider value={app}>{children}</AppContext.Provider>;
};

function useAppHook() {
  const [patient, setPatient] = useState<Patient | undefined>(undefined);
  const [study, setStudy] = useState<Study | undefined>(undefined);
  const [report, setReport] = useState<Report | undefined>(undefined);
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = qs.parse(search);

  const processChange = (field: string, newValue: number | string | string[], path?: string) => {
    const isPageChange = field === "page";
    const newQuery = omit<ParsedQuery>(query, isPageChange ? field : ["page", field]);

    if (newValue) {
      if (field === "status") {
        delete newQuery.unassigned;
      } else if (field === "unassigned") {
        delete newQuery.status;
      }

      if (field === "modalities") {
        newQuery[field] = newValue as string[];
      } else {
        newQuery[field] = String(newValue);
      }

      if (!isPageChange) {
        newQuery.page = String(1);
      }
    }

    if (path) {
      navigate(`${path}?${qs.stringify(newQuery)}`);
    } else {
      navigate({
        search: qs.stringify(newQuery),
      });
    }
  };

  const processPageSizeChange = (newValue: string | number) => {
    const newQuery = omit(query, ["page_size", "page"]);

    if (newValue) {
      newQuery.page_size = String(newValue);
      newQuery.page = String(1);
    }

    navigate({
      search: qs.stringify(newQuery),
    });
  };

  return {
    navigation: { query, processChange, processPageSizeChange },
    patient,
    setPatient,
    study,
    setStudy,
    report,
    setReport,
  };
}
