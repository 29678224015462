import moment from "moment";
import { Study } from "types/models/Study";

export function initStudyDateTime(study: Study) {
  study.date = moment(study.date).format("ll");

  if (study.patient) {
    study.patient.date_of_birth = moment(study.patient.date_of_birth).format("ll");
  }

  study.due = `12h`;
  return study;
}
