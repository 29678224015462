import React, { FC, MouseEvent } from "react";
import { Box } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useLocation } from "react-router-dom";

import useStyles from "styles/studyListStyles";
import { useApp } from "hooks/useApp";
import { useStudy } from "state/selector";
import { StudyStatusSummary } from "./StudyStatusSummary";

export const STATUS_CHOICES = [
  { value: "ready", label: "Unread", showPriorityDisplay: true },
  { value: "completed", label: "Completed", showPriorityDisplay: false },
  { value: "unassigned", label: "Unassigned", showPriorityDisplay: false },
  { value: "unready", label: "In acquisition", showPriorityDisplay: true },
  { value: "all", label: "All", showPriorityDisplay: false },
];

export const StudyStatusButtons: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const path = location.pathname;
  const {
    navigation: { query, processChange },
  } = useApp();
  const { isLoading } = useStudy();

  const isStudyPage = path === "/studies";

  const status = query.unassigned ? "unassigned" : query.status || "all";

  const onChange = (_: MouseEvent<HTMLElement>, value: string) => {
    if (value !== "all") {
      if (value === "unassigned") {
        processChange("unassigned", "True", "/studies");
      } else {
        processChange("status", value, "/studies");
      }
    } else {
      processChange(status === "unassigned" ? "unassigned" : "status", "", "/studies");
    }
  };

  return (
    <Box data-testid="study-status-buttons">
      <ToggleButtonGroup value={isStudyPage && status} exclusive onChange={onChange} size="small">
        {STATUS_CHOICES.map(({ label, value, showPriorityDisplay }) => (
          <ToggleButton
            className={classes.studyStatusToggleButton}
            value={value}
            aria-label={value}
            key={value}
            disabled={isLoading}
            data-testid={`study-status-button-${value}`}
          >
            <StudyStatusSummary value={value} label={label} showPriorityDisplay={showPriorityDisplay} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};
