import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";

const ProfileModal = ({ openPopup, children }) => (
  <div>
    <Dialog
      open={openPopup}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          backgroundColor: "#191B1E",
          border: "1px #000000",
          borderRadius: "2px",
        },
      }}
    >
      <DialogContent style={{ width: "960px", height: "540px" }}>{children}</DialogContent>
    </Dialog>
  </div>
);

export default ProfileModal;
