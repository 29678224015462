import React from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import { AppBar, Box, Chip, IconButton, Toolbar, Tooltip } from "@material-ui/core";
import RecordingListDialog from "components/RecordingDialog/RecordingListDialog";

import useStyles from "styles/appStyles";
import { useApp } from "hooks/useApp";
import UserProfileMenu from "components/NavBar/UserProfileMenu";
import LeftNavBarLinks from "components/NavBar/LeftNavBarLinks";
import StudyMenu from "components/NavBar/StudyMenu";
import { CakeRounded, RemoveRedEye } from "@material-ui/icons";
import moment from "moment";
import { PriorityRenderer } from "components/StudyList/StudyListTable";

const NavBar = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { patient, study, report } = useApp();
  const [recordingDialogOpen, setRecordingDialogOpen] = React.useState(false);

  return (
    <AppBar position="static">
      <Box>
        <Toolbar className={classes.customizeToolbar}>
          <LeftNavBarLinks />
          {(pathname.startsWith("/study/") || pathname.startsWith("/report/")) && patient && (
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Chip label={<PriorityRenderer value={study.priority || "R"} />} color="secondary" size="small" />
              <h1 style={{ fontWeight: "bold" }}>{patient.name}</h1>
              <Chip label={patient.age} color="secondary" size="small" />
              <Chip label={patient.sex} color="secondary" size="small" />
              <Chip
                icon={<CakeRounded />}
                label={moment(patient.date_of_birth).format("ll")}
                color="secondary"
                size="small"
              />
            </Box>
          )}
          <div className={classes.toolbarButtons}>
            {pathname.startsWith("/study/") && study && (
              <StudyMenu study={study} setRecordingDialogOpen={setRecordingDialogOpen} />
            )}
            {pathname.startsWith("/report/") && study && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Navigate to the study viewer">
                  <a aria-label="study" href={`/study/${study.id}`} target="_blank" rel="noreferrer">
                    <IconButton>
                      <RemoveRedEye />
                    </IconButton>
                  </a>
                </Tooltip>
              </Box>
            )}
          </div>
          <UserProfileMenu />
        </Toolbar>
      </Box>
      {study && <RecordingListDialog study={study} open={recordingDialogOpen} onClose={setRecordingDialogOpen} />}
    </AppBar>
  );
};

export default NavBar;
