import { makeStyles } from "@material-ui/core/styles";
import { convertOpacityIntoHexString } from "styles/util";
import { AppTheme } from "types/AppTheme";

const useStyles = makeStyles((theme: AppTheme) => ({
  studyListRoot: {
    width: "100%",
    height: "100%",
    paddingTop: "30px",
  },
  studyList: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  overlayLoading: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
    background: "rgba(0,0,0,0.4)",
  },
  rangeInput: {
    display: "flex",
    flexDirection: "row",

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "5px",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "5px",
    },
    "& .MuiTextField-root:first-child": {
      marginRight: "15px",
    },
  },
  studyListActions: {
    display: "flex",
    flexDirection: "row",
  },
  studyListTable: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: "10px",
      "& .MuiInput-underline:before, .MuiInput-underline:after": {
        borderBottom: `1px solid ${theme.palette.common.white}${convertOpacityIntoHexString(0.42)} !important;`,
      },
      "& .MuiTableCell-root": {
        borderBottom: `1px solid ${theme.palette.shadows.dark}${convertOpacityIntoHexString(0.2)}`,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "& svg": {
          color: `${theme.palette.primary.main}${convertOpacityIntoHexString(0.8)}`,
        },
      },
      "& .MuiTable-root": {
        width: "100%",
        tableLayout: "auto",
      },
    },
    "& .MuiToolbar-root": {
      minHeight: "64px",
      borderBottom: `1px solid ${theme.palette.shadows.dark}${convertOpacityIntoHexString(0.2)}`,
    },
    "& .MuiTableHead-root": {
      "& .MuiTableCell-root": {
        padding: "6px 12px 6px 12px",
        color: theme.palette.common.main,
        // fontWeight: "550",
        borderBottom: `transparent !important`,
      },
    },
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root:hover:not(:first-child)": {
        background: "#0f172a",
        cursor: "pointer",
        "& .MuiTableCell-root": {
          whiteSpace: "normal",
          overflow: "visible",
          textOverflow: "clip",
        },
      },
      "& .MuiTableRow-root:first-child": {
        "& .MuiTableCell-root": {
          padding: "0px 12px 8px 12px",
          fontSize: "0.85em",
        },
      },
      "& .MuiTableCell-root": {
        padding: "4px 12px 4px 12px",
        fontSize: "0.75rem !important",
        "& .MuiFormControl-root fieldset": {
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderRadius: 0,
        },
      },
    },
    "& .MuiTableFooter-root": {
      "& .MuiToolbar-root": {
        borderBottom: `none`,
      },
      "& .MuiInputBase-input": {
        color: theme.palette.common.bright,
      },
      "& .MuiTypography-caption": {
        color: theme.palette.common.bright,
      },
      "& .MuiTableCell-root": {
        borderBottom: "none !important",
      },
    },
  },
  studyStatusToggleButton: {
    padding: "6px 15px 6px 15px !important",
    fontWeight: "bold",
    border: `1px solid ${theme.palette.secondary.dark} !important`,
    color: `${theme.palette.common.light} !important`,

    "&:first-child": {
      borderTopLeftRadius: "7px",
      borderBottomLeftRadius: "7px",
    },
    "&:last-child": {
      borderTopRightRadius: "7px",
      borderBottomRightRadius: "7px",
    },
    "&.Mui-selected": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
    },
  },
  studyHistoryToggleButton: {
    padding: "6px 15px 6px 15px !important",
    fontWeight: "bold",
    border: `1px solid ${theme.palette.secondary.dark} !important`,
    color: `${theme.palette.common.light} !important`,
    "&:first-child": {
      borderTopLeftRadius: "7px",
      borderBottomLeftRadius: "7px",
    },
    "&:last-child": {
      borderTopRightRadius: "7px",
      borderBottomRightRadius: "7px",
    },
    "&.Mui-selected": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
    },
  },
  studyHistoryToggleButtonGroup: {
    marginRight: "10px",
  },
  studyStatusSummary: {
    textTransform: "capitalize",
    marginBottom: 0,
    fontSize: "0.8rem",
    fontWeight: "bold",
  },

  assignedToFilter: {
    width: "95%",
    "& .MuiAutocomplete-root": {
      width: "100%",
    },
  },
  assignedToRow: {
    width: "95%",
    "& .MuiAutocomplete-root": {
      width: "100%",
    },
  },
  contextMenu: {
    "& .MuiMenu-list": {
      padding: 0,
      "& .MuiMenuItem-root": {
        fontSize: "1.1em",
        padding: "0.75em 1.25em",
      },
    },
  },
  uploadDicomButton: {
    borderRadius: "7px",
    marginLeft: "24px",
    border: `1px solid ${theme.palette.secondary.light}`,
    textTransform: "capitalize",
    marginBottom: 0,
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: theme.palette.common.light,
    "& .MuiButton-startIcon": {
      marginTop: "-1px",
    },
    width: "125px",
  },
}));

export default useStyles;
